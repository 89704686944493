$(function() {
    if ($('.main-slider').length) {
        var swiper = new Swiper(".main-slider", {
            speed: 1400,
            loop: true,
            effect: "slide",
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
            },
        });
    }

    if ($('.main-mobile-slider').length) {
        var swiper_mobile = new Swiper(".main-mobile-slider", {
            speed: 1400,
            loop: true,
            effect: "slide",
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            }
        });
    }

    $(document).on('submit', '#appeal-form', function(event) {
        event.preventDefault();
        $('.error').text('');
        $.ajax({
            url: "/send-appeal",
            type: "POST",
            data: new FormData(this),
            processData: false,
            contentType: false,
            success:function(response){
                if (response.success) {
                    $('.contact-form-success').text(response.success);
                    $('.contact-form-success').css('display', 'block');
                    $('.contact-form-area').css('display', 'none');
                }
            },
            error: function (data) {
                $('.appeal-form-name-error').text(data.responseJSON.errors.name);
                $('.appeal-form-address-error').text(data.responseJSON.errors.address);
                $('.appeal-form-email-error').text(data.responseJSON.errors.email);
                $('.appeal-form-phone-error').text(data.responseJSON.errors.phone);
                $('.appeal-form-message-error').text(data.responseJSON.errors.message);
                $('.appeal-form-files-error').text(data.responseJSON.errors.user_files);
            }
        });
        return false;
    });

    $(document).on('submit', '#subscribe-form', function(event) {
        event.preventDefault();
        $('.subscribe-form-success').text('');
        $('.error').text('');
        $.ajax({
            url: "/subscribe",
            type: "POST",
            data: $('#subscribe-form').serialize(),
            success:function(response){
                if (response.success) {
                    $('.subscribe-form-success').text(response.success);
                }
            },
            error: function (data) {
                $('.subscribe-form-email-error').text(data.responseJSON.errors.email);
            }
        });
        return false;
    });


    /*$(document).on('click', '.header-appeal-button', function() {
        $('#contact-modal').modal('show');
    })

    $(document).on('click', '.close-modal', function() {
        $('#contact-modal').modal('hide');
    })*/

    if ($('.articleSwiper2').length > 0 && $('.articleSwiper').length > 0) {
        var article_swiper = new Swiper(".articleSwiper", {
            spaceBetween: 10,
            slidesPerView: 5,
            freeMode: true,
            watchSlidesProgress: true
        });
        var article_swiper2 = new Swiper(".articleSwiper2", {
            loop: true,
            speed: 600,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            effect: "fade",
            spaceBetween: 10,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            thumbs: {
                swiper: article_swiper,
            },
        });
    }

    if($('.mobile-menu').length){
        //Dropdown Button
        $('.mobile-menu li.dropdown .dropdown-btn').on('click', function() {
            $(this).toggleClass('open');
            $(this).prev('ul').slideToggle(500);
        });
        //Menu Toggle Btn
        $('.mobile-nav-toggler').on('click', function() {
            $('body').addClass('mobile-menu-visible');
        });

        //Menu Toggle Btn
        $('.mobile-menu .menu-backdrop,.mobile-menu .close-btn,.scroll-nav li a').on('click', function() {
            $('body').removeClass('mobile-menu-visible');
        });
    }

    let timeout = null;

    $(document).on('submit', '.search-form', function() {
        if (!$('.header-search-input').val() || $('.header-search-input').val().replace(/ /g,'').length < 3) {
            $('.search-container').tooltip('show');
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }

            timeout = setTimeout(function() {
                $('.search-container').tooltip('hide');
            }, 3000);
            return false;
        }
    });

    $(document).on('click', '.search-type-switcher', function(e) {
        e.preventDefault();

        if ($(this).attr('type') == 'articles') {
            $('.articles-search-container').css('display', 'block');
            $('.pages-search-container').css('display', 'none');
        } else {
            $('.articles-search-container').css('display', 'none');
            $('.pages-search-container').css('display', 'block');
        }
    })

    $('[data-toggle="tooltip"]').tooltip();

    if ($('#date_from').length && $('#date_to').length) {

        let locale_uk = {
            days: ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П’ятниця', 'Субота'],
            daysShort: ['Нед', 'Пнд', 'Вів', 'Срд', 'Чтв', 'Птн', 'Сбт'],
            daysMin: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
            monthsShort: ['Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру'],
            today: 'Сьогодні',
            clear: 'Очистити',
            dateFormat: 'dd.MM.yyyy',
            timeFormat: 'HH:mm',
            firstDay: 1
        };

        new AirDatepicker('#date_from', {
            locale: locale_uk,
            selectedDates: [new Date($('#date_from').attr('val'))],
            isMobile: window.innerWidth <= 500 ? true : false,
            onSelect: function(date) {
                if (!$('#date_to').val()) {
                    $('#date_to').val(date.formattedDate);
                }
            }
        });
        new AirDatepicker('#date_to', {
            locale: locale_uk,
            selectedDates: [new Date($('#date_to').attr('val'))],
            isMobile: window.innerWidth <= 500 ? true : false,
            onSelect: function(date) {
                if (!$('#date_from').val()) {
                    $('#date_from').val(date.formattedDate);
                }
            }
        });
    }
});
